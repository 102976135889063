<template>
  <div class="document-request-from">
    <Header :typeComponent="'online-consultation'"/>
    <v-col>
      <v-row class="flex-fill">
        <BaseContentIntro>
          <template v-slot:title>
            <span class="color-title">{{ $t('documentRequest.onlineConsultation.settingsTitle') }}</span>
          </template>
          <template v-slot:default>
            <v-row class="flex-fill pa-9 pb-0 pt-6">
              <v-col sm="12" md="12" cols="12">
                <v-row class="flex-fill align-start d-block">
                  <p class="mb-1">オンライン 個別相談</p>
                </v-row>

                <v-row class="flex-fill align-start d-block pt-6">
                  <p>ご自宅など、インターネットに接続できる環境であれば どこからでも気軽にオンラインでご相談できるサービスです。</p>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="flex-fill pa-9 pb-0 pt-0">
              <v-col sm="12" md="12" cols="12">
                <v-row class="flex-fill align-start d-block pt-6">
                  <p class="mb-1">パソコンを利用してご案内いたします。<br>パソコンの画面で資料をご覧いただきながら、営業担当より個別にご説明いたします。
                  </p>
                  <p>ぜひお気軽にご参加ください。</p>
                </v-row>

                <v-row class="flex-fill align-start d-block pt-6">
                  <p class="mb-1">※完全予約制となっております。</p>
                  <p class="mb-1">※本フォームでのお申込みは、オンライン相談のご予約を確定するものではありません。</p>
                  <p class="mb-1">ご希望の方は下記のお問合わせフォームからお申し込みください。</p>
                  <p class="mb-1">後日、改めて担当者よりご連絡いたします。</p>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </BaseContentIntro>
      </v-row>
    </v-col>

    <BreadcrumbStep :dataItems="dataBreadcrumbs" @eventChangeStep="handlerClickStep"/>

    <template v-if="stepOnlineConsultationType === constant.STEP_BY_STEP.STEP_1">
      <p class="step1"></p>
      <MainFormDocRequest @eventChangeDataDocReq="handlerMainFormDocRequest"
                          :dataModel="dataMainFormDocRequest"/>

      <FormDividerDuplication/>

      <MainFormVisitOnline :optionalTitleRowVisit="'オンライン相談<br>希望日時'"
                           :type="typeApply"
                           @eventChangeDataVisitOnlineRequest="handlerOnlineReservationOptional"
                           :dataModel="dataOnlineOptional"/>
      <FormDividerDuplication/>

      <PolicyAgree @eventPolicyAgree="handlerPolicyAgree"
                   :dataModel="dataPolicyAgree"/>

      <v-col class="d-flex justify-center align-center d-box-act mb-9">
        <v-btn class="btn-action-from pa-0 pl-2" color="primary" :disabled="valid" @click="funcConfirmStep1Form">
          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
          {{ $t('buttons.confirmAndSend') }}
        </v-btn>
      </v-col>
    </template>

    <template v-if="stepOnlineConsultationType === constant.STEP_BY_STEP.STEP_2">
      <p class="step2"></p>
      <ShowDataOnlineStep2 :type="typeApply"/>

      <v-row class="d-flex justify-center align-center d-box-act mt-4 mb-9">
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="btn-action-from pa-0 pl-2" text @click="funcBackStep1Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-left</v-icon>
            {{ $t('buttons.back') }}
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-center align-center d-box-act">
          <v-btn class="ml-2 pl-2 btn-action-from pa-0" color="primary" @click.prevent="funcSubmitStep2Form" :disabled="noClickStep2">
            <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>
            {{ $t('buttons.saveAndCreate') }}
            <v-progress-circular v-if="noClickStep2" indeterminate color="primary" :size="18" :width="2"/>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="stepOnlineConsultationType === constant.STEP_BY_STEP.STEP_3">
      <ShowDataStep3 />
      <!--      <v-col class="d-flex justify-center align-center d-box-act">-->
      <!--        <v-btn class="btn-action-from" text @click="funcAfterSubmitStep3Form">-->
      <!--          <v-icon x-large right color="white" class="b-right">mdi-chevron-right</v-icon>-->
      <!--          TODO: After Submit success ....-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
    </template>

    <Footer :typeComponent="'form'"/>
  </div>
</template>

<script>
import BaseContentIntro from '@/components/common/BaseContentIntro'
import BreadcrumbStep from '@/components/common/BreadcrumbStep'
import PolicyAgree from '@/components/common/PolicyAgree'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MainFormVisitOnline from '@/components/common/MainFormVisitOnline'
import ShowDataOnlineStep2 from '@/components/documentRequest/onlineConsultation/ShowDataOnlineStep2'
import MainFormDocRequest from '@/components/common/MainFormDocRequest'
import ShowDataStep3 from '@/components/common/ShowDataStep3'
import FormDividerDuplication from '@/components/common/FormDividerDuplication'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'

export default {
  mixins: [
    createWebRequest
  ],
  data () {
    return {
      noClickStep2: false,
      typeValidMainFormDocRequest: 'REQ_CHECKBOX_MFOC',
      typeValidPolicyAgree: 'REQ_CHECKBOX_PA'
    }
  },
  components: {
    MainFormVisitOnline,
    ShowDataStep3,
    ShowDataOnlineStep2,
    FormDividerDuplication,
    MainFormDocRequest,
    BreadcrumbStep,
    BaseContentIntro,
    PolicyAgree,
    Footer,
    Header
  },
  mounted () {
    this.$store.dispatch('stepOnlineConsultationTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
    this.typeValidPolicyAgree = this.dataOnlineConsultation.policyAgree ? '' : 'REQ_CHECKBOX_PA'
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      stepOnlineConsultationType: state => state.documentRequest.stepOnlineConsultationType
    }),
    dataPolicyAgree () {
      // Get data for v-model
      return this.dataOnlineConsultation.policyAgree
    },
    dataMainFormDocRequest () {
      // Get data for v-model
      return this.dataOnlineConsultation.mainFormDocRequest
    },
    dataOnlineOptional () {
      // Get data for v-model
      return this.dataOnlineConsultation.onlineConsultationOptional
    },
    dataBreadcrumbs () {
      return [{
        step: 1,
        name: this.$t('documentRequest.stepByStep.step1'),
        active: this.constant.STEP_BY_STEP.STEP_1 === this.stepOnlineConsultationType
      }, {
        step: 2,
        name: this.$t('documentRequest.stepByStep.step2'),
        active: this.constant.STEP_BY_STEP.STEP_2 === this.stepOnlineConsultationType
      }, {
        step: 3,
        name: this.$t('documentRequest.stepByStep.step3'),
        active: this.constant.STEP_BY_STEP.STEP_3 === this.stepOnlineConsultationType
      }]
    },
    valid () {
      return [
        this.typeValidPolicyAgree,
        this.typeValidMainFormDocRequest
      ].some(ele => ele.length)
    }
  },
  methods: {
    handlerClickStep (data) {
      // console.log(data)
    },
    handlerPolicyAgree (dataBoolean) {
      this.typeValidPolicyAgree = dataBoolean ? '' : 'REQ_CHECKBOX_PA'
      this.$store.dispatch('policyAgreeDispatch', { policyAgree: dataBoolean, typeApply: this.typeApply })
    },
    handlerMainFormDocRequest (data) {
      this.typeValidMainFormDocRequest = data?.isValid && data.isValid ? '' : 'REQ_CHECKBOX_MFOC'
      this.$store.dispatch('mainFormOnlineConsultationRequestDispatch', data.data)
    },
    handlerOnlineReservationOptional (data) {
      this.$store.dispatch('mainFormOnlineOptionalRequestDispatch', data.data)
    },
    funcConfirmStep1Form () {
      this.$store.dispatch('stepOnlineConsultationTypeDispatch', this.constant.STEP_BY_STEP.STEP_2)
      this.scrollIntoViewSmooth('.step2')
    },
    funcBackStep1Form () {
      this.$store.dispatch('stepOnlineConsultationTypeDispatch', this.constant.STEP_BY_STEP.STEP_1)
      this.scrollIntoViewSmooth('.step1')
    },
    async funcSubmitStep2Form () {
      if (this.noClickStep2) {
        this.showMessageDbClick(this.$toast, 'w', this.$t('common.notSaveWhenDbClick'))
        return
      }
      this.noClickStep2 = true
      const payload = await this.convertDataBeforeForOnlineConsultation()

      await this.$store.dispatch('createWebRequestDispatch', {
        apolloClient: this.$apollo,
        payload,
        typeApply: this.typeApply,
        toast: this.$toast
      }).then(res => {
        if (res) {
          this.scrollIntoViewSmooth('.step3')
          return
        }

        // eslint-disable-next-line no-return-assign
        setTimeout(() => this.noClickStep2 = false, 3200)
      })
    },
    funcAfterSubmitStep3Form () {
      // TODO
    }
  },
  created () {
    // Setup for component use data
    this.typeApply = 'ONLINE_CONSULTATION'
  }
}
</script>

<style lang="scss">
.d-duplication-divider {
  margin-top: 48px;
}
.cust-vchip-online {
  background: #004BB1 !important;
  span.v-chip__content {
    padding: 5px 10px;
  }
}
</style>
