<template>
  <div class="document-request-from-show-step2">

    <MainFormRequestShow :type="type" />
    <v-col cols="12"></v-col>
    <MainFormDateTimeOtherShow :type="type" />
  </div>
</template>

<script>
import C from '@/utils/constant'
import { mapState } from 'vuex'
import MainFormRequestShow from '@/components/common/ShowDataStep2/MainFormRequestShow'
import MainFormDateTimeOtherShow from '@/components/common/ShowDataStep2/MainFormDateTimeOtherShow'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  components: {
    MainFormDateTimeOtherShow,
    MainFormRequestShow
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      dataVisitReservation: state => state.documentRequest.dataVisitReservation
    }),
    constant () {
      return Object.assign({}, C)
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
