<template>
  <div class="document-request-dto-show-step2">
    <v-card class="mt-30">
      <v-card-text class="ma-0 pa-0">
        <v-row class="d-row-from-border ma-0" v-if="hasDateTimeOtherAllField">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご来店希望日時</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" cols="12">
            <v-row class="bd-from-bottom" v-if="checkFirstDataTimeOptional">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">第１希望</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ firstDateTime || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom" v-if="checkSecondDataTimeOptional">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">第２希望</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ secondDateTime || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom" v-if="checkOtherDataTimeOptional">
              <v-col md="12" sm="12" cols="12" class="bd-from-right text-start">
                <span class="d-label-left">上記日時以外をご希望の場合は、こちらにご入力ください。</span>
              </v-col>
              <v-col md="12" sm="12" cols="12" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap break-all">{{ dataFormDataTimeOtherOfVisitOnline.otherDateTime || '' }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0" v-if="hasInquiry">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご相談内容</span>
            <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
              {{ $t('common.labelOptional') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap break-all">{{ dataFormDataTimeOtherOfVisitOnline.inquiry || '' }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import C from '@/utils/constant'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'

export default {
  name: 'MainFormDateTimeOtherShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
    }),
    constant () {
      return Object.assign({}, C)
    }
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
</style>
