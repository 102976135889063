<template>
  <v-card class="mt-11">
    <v-form ref="formVisit">
      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3" v-html="optionalTitleRowVisit"></span>
          <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
            {{ $t('common.labelOptional') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="flex-fill ml-3">
            <v-col cols="12" md="12" sm="12" class="ma-0 pa-0">
              <v-row md="12" sm="12" class="pa-0 ma-0">
                <v-col sm="2" md="4" cols="12" class="pr-0 align-self-center pb-0">
                  <label class="">第１希望</label>
                </v-col>
                <v-col sm="5" md="4" class="pr-6" cols="12">
                  <v-menu
                    v-model="menuFirstDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedDateFirstDate"
                        label="日付を選択"
                        hint="日付を選択"
                        persistent-hint
                        readonly
                        clearable
                        solo
                        clea
                        hide-details
                        v-on="on"
                        @click:clear="vModelDataFormVisit.firstDate = null"
                      >
                        <template v-slot:append>
                          <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                                 src="@/assets/images/icon-calendar-alt.svg"
                                 contain
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="vModelDataFormVisit.firstDate"
                      no-title
                      :min="nowDate"
                      @input="menuFirstDate = false"
                    />
                  </v-menu>
                </v-col>
                <v-col sm="5" md="4" class="pr-6" cols="12">
                  <v-menu
                    ref="menuFirstTime"
                    v-model="menuFirstTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="vModelDataFormVisit.firstTime"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vModelDataFormVisit.firstTime"
                        label="時間帯を選択"
                        readonly
                        v-bind="attrs"
                        hide-details
                        clearable
                        solo
                        v-on="on"
                      >
                        <template v-slot:append>
                          <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                                 src="@/assets/images/icon-arrow-down.svg"
                                 contain
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      use-minute
                      v-if="menuFirstTime"
                      v-model="vModelDataFormVisit.firstTime"
                      @click:minute="$refs.menuFirstTime.save(vModelDataFormVisit.firstTime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col sm="2" md="4" cols="12" class="pr-6 align-self-center pb-0">
                  <label class="font-label-sp">第２希望</label>
                </v-col>
                <v-col sm="5" md="4" class="pr-6" cols="12">
                  <v-flex>
                    <v-menu
                      v-model="menuSecondDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="vModelDataSecondDate"
                          label="日付を選択"
                          hint="日付を選択"
                          persistent-hint
                          readonly
                          clearable
                          solo
                          hide-details
                          v-on="on"
                          @click:clear="vModelDataFormVisit.secondDate = null"
                        >
                          <template v-slot:append>
                            <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                                   src="@/assets/images/icon-calendar-alt.svg"
                                   contain
                            />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vModelDataFormVisit.secondDate"
                        no-title
                        :min="nowDate"
                        @input="menuSecondDate = false"
                      />
                    </v-menu>
                  </v-flex>
                </v-col>
                <v-col sm="5" md="4" class="pr-6" cols="12">
                  <v-menu
                    ref="menuSecondTime"
                    v-model="menuSecondTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="vModelDataFormVisit.secondTime"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="vModelDataFormVisit.secondTime"
                        label="時間帯を選択"
                        readonly
                        v-bind="attrs"
                        hide-details
                        clearable
                        solo
                        v-on="on"
                      >
                        <template v-slot:append>
                          <v-img width="" height="" class="ma-0 pa-0" v-on="on"
                                 src="@/assets/images/icon-arrow-down.svg"
                                 contain
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      use-minute
                      v-if="menuSecondTime"
                      v-model="vModelDataFormVisit.secondTime"
                      @click:minute="$refs.menuSecondTime.save(vModelDataFormVisit.secondTime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="12" sm="12" class="ma-0 pa-0 mt-3">
              <div class="ml-3 mr-3">
                <label class="font-label-sp">上記日時以外をご希望の場合は、こちらにご入力ください。</label>
              </div>
              <v-row class="pa-0 ma-0 mr-6">
                <v-col cols="12" sm="12" md="12" class="pr-0">
                  <v-text-field
                    required
                    v-model="vModelDataFormVisit.otherDateTime"
                    label=""
                    hide-details="auto"
                    solo />
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <div class="d-label-left ml-3">
            <label class="font-label-sp">ご相談内容</label>
          </div>
          <v-chip class="ma-2 d-label-optional-bg" label text-color="white">
            {{ $t('common.labelOptional') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                class="d-textarea"
                v-model="vModelDataFormVisit.inquiry"
                hide-details
                rows="3"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      vModelDataFormVisit: {
        firstDate: null,
        firstTime: null,
        secondDate: null,
        secondTime: null,
        otherDateTime: null,
        inquiry: ''
      },
      menuSecondDate: false,
      menuFirstDate: false,
      menuSecondTime: false,
      menuFirstTime: false
    }
  },
  watch: {
    vModelDataFormVisit: {
      deep: true,
      handler () {
        this.eventDataVisitPush()
      }
    }
  },
  model: {
    event: ['eventChangeDataVisitOnlineRequest']
  },
  props: {
    optionalTitleRowVisit: {
      type: String,
      required: false,
      defaultValue: ''
    },
    dataModel: {
      type: Object,
      required: false
    },
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
    if (this.dataModel && Object.keys(this.dataModel).length && (this.type === 'VISIT_RESERVATION' || this.type === 'ONLINE_CONSULTATION')) {
      this.vModelDataFormVisit = this.dataModel
    }
  },
  methods: {
    eventDataVisitPush () {
      this.$nextTick(() => {
        this.$emit('eventChangeDataVisitOnlineRequest', {
          data: this.vModelDataFormVisit,
          isValid: true // Optional
        })
      })
    },
    formatDate (date) {
      if (!date) return null

      return date
    }
    // formatDateOther (date) {
    //   if (!date) return null
    //
    //   return date.replace('T', ' ')
    // },
    // changeData (event) {
    //   event.target.setAttribute('type', 'datetime-local')
    //   this.vModelDataFormVisit.otherDateTime = this.formatDateOther(event?.target?.value)
    //   this.$forceUpdate()
    // }
  },
  computed: {
    computedDateFirstDate () {
      return this.formatDate(this.vModelDataFormVisit?.firstDate)
    },
    vModelDataSecondDate () {
      return this.formatDate(this.vModelDataFormVisit?.secondDate)
    }
    // valueDateOther () {
    //   if (!this.vModelDataFormVisit?.otherDateTime) {
    //     return null
    //   }
    //
    //   return this.vModelDataFormVisit?.otherDateTime.replace(' ', 'T')
    // }
  }
}
</script>

<style lang="scss">
.d-row-from {
  border: 1px solid #CDD6DD;

  .d-with-ymd-child {
     .v-input__control .v-text-field__details {
       padding-left: 0;
       padding-right: 0;
     }
  }
}
.d-textarea {
  resize: none;
}
.d-box-company-size {
}

</style>
